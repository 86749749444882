//TODO-SIMEN REPLACE ALL THESE WITH LOCALIZED SOUNDS
import React from 'react';
import bananaWrong from '../../../assets/sounds/child/de/banana-is-wrong.mp3';
import arrowWrong from '../../../assets/sounds/child/de/arrow-is-wrong.mp3';
import tutorial6Sound from '../../../assets/sounds/child/de/Bytt-tutorial-6.mp3';
import lockCorrect from '../../../assets/sounds/child/de/lock-is-correct.mp3';
import doorWrong from '../../../assets/sounds/child/de/door-is-wrong.mp3';

import fireCorrect from '../../../assets/sounds/child/de/fire-is-correct.mp3';
import sunWrong from '../../../assets/sounds/child/de/sun-is-wrong.mp3';
import jetWrong from '../../../assets/sounds/child/de/jet-is-wrong.mp3';
import carWrong from '../../../assets/sounds/child/de/car-is-wrong.mp3';
import tutorial3Sound from '../../../assets/sounds/child/de/Bytt-tutorial-3.mp3';
import catWrong from '../../../assets/sounds/child/de/cat-is-wrong.mp3';
import kombCorrect from '../../../assets/sounds/child/de/komb-is-correct.mp3';
import readingWrong from '../../../assets/sounds/child/de/lese-is-wrong.mp3';
import lightWrong from '../../../assets/sounds/child/de/lys-is-wrong.mp3';
import tutorial1Sound from '../../../assets/sounds/child/de/Bytt-tutorial-1.mp3';

import can from '../assets/can.png';
import trouser from '../assets/trouser.png';

import sock from '../assets/Sokk.PNG';
import bug from '../assets/bug.png';
import rose from '../assets/rose.png';
import car from '../assets/car.png';
import peice from '../assets/piece.png';
import puzzle from '../assets/puzzle.png';
import dough from '../assets/dough.png';
import pond from '../assets/pond.png';

import navel from '../assets/navel.png';
import hammer from '../assets/hammer.png';
import nail from '../assets/nail.png';
import rabbit from '../assets/rabbit.png';

import needle from '../assets/Sy.PNG';

import starterTesten from '../../../assets/sounds/child/de/byttelydStartingtest.mp3';

export const starterTestenSound = starterTesten;

export const bytteLydenExample = {};

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'Lys',
		},
		{
			word: 'Lese',
		},
		{
			word: 'Katt',
		},
		{
			isCorrect: true,
			word: 'Kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
		mainImg: trouser,
		mainSound: tutorial1Sound,
		text:<span>Welches neue Wort kommt dabei heraus, wenn Du den ersten Laut <span style={{color:'yellow'}}>/ h /</span> in  <span style={{color:'yellow'}}>Hose</span> durch den Laut <span style={{color:'yellow'}}>/ r /</span> ersetzt? Klicke auf das passende Bild!</span>,
		choices: [
			{
				remark: false,
				word: 'Lys',
				comment: 'Dose ist leider falsch. Versuche es noch einmal!',
				sound: lightWrong,
				img: can,
			},
			{
				remark: true,
				word: 'Kam',
				comment: `Rose ist richtig! Wenn du den Laut /h/ in Hose durch /r/ ersetzt, 
				kommt das Wort „Rose“ heraus!`,
				sound: kombCorrect,
				img: rose,
			},
			{
				remark: false,
				word: 'Katt',
				comment: 'Käfer ist leider falsch. Versuche es noch einmal!',
				sound: catWrong,
				img: bug,
			},
			{
				remark: false,
				word: 'Lese',
				comment: 'Socke ist leider falsch. Versuche es noch einmal!',
				sound: readingWrong,
				img: sock,
			},
		],
	},
];

export const assignment2 = [
	{
		id: 2,
		mainImg: peice,
		mainSound: tutorial3Sound,
		text:<span>Welches neue Wort kommt dabei heraus, wenn Du den ersten Laut <span style={{color:'yellow'}}>/ l /</span> in  <span style={{color:'yellow'}}>Teil</span> durch den Laut <span style={{color:'yellow'}}>/ ch /</span> ersetzt? Klicke auf das passende Bild!</span>,
		choices: [
			{
				remark: false,
				word: 'Bil',
				comment: `Puzzle ist leider falsch. Versuche es noch einmal.`,
				sound: carWrong,
				img: puzzle,
			},
			{
				remark: false,
				word: 'Fly',
				comment: `Auto ist leider falsch. Versuche es noch einmal.`,
				sound: jetWrong,
				img: car,
			},
			{
				remark: false,
				word: 'Sol',
				comment: `Teig ist leider falsch. Versuche es noch einmal.`,
				sound: sunWrong,
				img: dough,
			},
			{
				remark: true,
				word: 'Bål',
				comment:`Teich ist richtig. Wenn du den Laut /l/ in Teil durch /ch/ ersetzt, 
				kommt das Wort „Teich“ heraus!`,
				sound: fireCorrect,
				img: pond,
			},
		],
	},
];

export const assignment3 = [
	{
		id: 3,
		mainImg: navel,
		mainSound: tutorial6Sound,
		text:<span>Welches neue Wort kommt dabei heraus, wenn Du den mittlerer Laut <span style={{color:'yellow'}}>/ b /</span> in  <span style={{color:'yellow'}}>Nabel</span> durch den Laut <span style={{color:'yellow'}}>/ d /</span> ersetzt? Klicke auf das passende Bild!</span>,
		choices: [
			{
				remark: false,
				word: 'Pil',
				comment: 'Hase ist leider falsch. Versuche es noch einmal.',
				sound: arrowWrong,
				img: rabbit,
			},
			{
				remark: false,
				word: 'Banan',
				comment: 'Hammer ist leider falsch. Versuche es noch einmal.',
				sound: bananaWrong,
				img: hammer,
			},
			{
				remark: false,
				word: 'Dør',
				comment: 'Nagel ist leider falsch. Versuche es noch einmal.',
				sound: doorWrong,
				img: nail,
			},
			{
				remark: true,
				word: 'Lock',
				comment:`Nadel ist richtig. Wenn du den Laut /b/ in Nabel durch /d/ ersetzt, kommt das Wort „Nadel“ heraus!`,
				sound: lockCorrect,
				img: needle,
			},
		],
	},
];
export const assignment4 = [
	{
		id: 4,
		mainImg: can,
		choices: [
			{
				remark: true,
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: can,
			},
			{
				remark: true,
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: can,
			},
			{
				remark: true,
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: can,
			},
			{
				remark: true,
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: can,
			},
		],
	},
];
export const assignment5 = [
	{
		id: 5,
		mainImg: can,
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: can,
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: can,
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: can,
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: can,
			},
		],
	},
];

 export const assignments = [
	{
		id: 2,
	
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				
			},
		],
	},
	{
		id: 3,
		
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi sier ikke peano – men piano. Ordet peano finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},
			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},
		],
	},
	{
		id: 4,
	
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
			
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
			
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi sier ikke peano – men piano. Ordet peano finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
			
			},

			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},
		],
	},
	{
		id: 5,
		
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},

			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: can,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},
		],
	},
	{
		id: 6,
	
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},

			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: can,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				
			},
		],
	},
]; 
